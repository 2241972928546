(function () {
    "use strict";

    angular
        .module("edistradadhluiApp")
        .factory("AuthServerProvider", AuthServerProvider);

    AuthServerProvider.$inject = [
        "$rootScope",
        "$http",
        "$localStorage",
        "Base64",
    ];

    function AuthServerProvider($rootScope, $http, $localStorage, Base64) {
        var service = {
            getToken: getToken,
            hasValidToken: hasValidToken,
            login: login,
            logout: logout,
            isBlocked: isBlocked,
        };

        return service;

        function getToken() {
            return $localStorage.authenticationToken;
        }

        function hasValidToken() {
            return !!this.getToken();
        }

        function login(credentials) {
            var base64EncodedString = Base64.encode(
                credentials.username + ":" + credentials.password
            );
            var authorizationHeader = base64EncodedString
                ? "Basic " + base64EncodedString
                : "Basic";

            return $http.post($rootScope.API_URL + "api/authenticate", "", {
                headers: {
                    Authorization: authorizationHeader,
                },
                withCredentials: true,
            });
        }

        function isBlocked(email) {
            return $http({
                method: "GET",
                url:
                    $rootScope.API_URL +
                    "api/isBlocked?email=" +
                    email +
                    "&gr=BENELUX",
                withCredentials: true,
            });
        }

        function logout() {
            return $http
                .post(
                    $rootScope.API_URL + "api/logout",
                    {},
                    {
                        withCredentials: true,
                    }
                )
                .then(function (response) {
                    delete $localStorage.authenticationToken;
                    return response;
                })
                .catch(function (error) {
                    console.error("Logout error:", error);
                });
        }
    }
})();
