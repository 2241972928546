(function () {
    "use strict";

    angular
        .module("edistradadhluiApp")
        .controller("SystemSettingsController", SystemSettingsController);

    SystemSettingsController.$inject = [
        "API_URL",
        "$scope",
        "$translate",
        "ConstantsService",
        "Upload",
        "$rootScope",
        "MailTemplatesService",
        "$uibModal",
        "FileSaver",
        "Blob",
        "UtilService",
        "filterFilter",
        "ClientsService",
        "Principal",
    ];

    function SystemSettingsController(
        API_URL,
        $scope,
        $translate,
        ConstantsService,
        Upload,
        $rootScope,
        MailTemplatesService,
        $uibModal,
        FileSaver,
        Blob,
        UtilService,
        filterFilter,
        ClientsService,
        Principal
    ) {
        var vm = this;
        vm.translate = $translate.instant;

        vm.ActivityTypes = [
            {
                name: "ACCOUNT_OPENED",
                text: function () {
                    return $translate.instant(
                        "systemSettings.templateType.open"
                    );
                },
            },
            {
                name: "EMAIL_ADDED",
                text: function () {
                    return $translate.instant(
                        "systemSettings.templateType.emailAdded"
                    );
                },
            },
            {
                name: "REGISTRATION_FAIL",
                text: function () {
                    return $translate.instant(
                        "systemSettings.templateType.invalidRegistration"
                    );
                },
            },
            {
                name: "PASSWORD_CHANGE",
                text: function () {
                    return $translate.instant(
                        "systemSettings.templateType.reset"
                    );
                },
            },
            {
                name: "INVOICE_WITH_LINK",
                text: function () {
                    return $translate.instant(
                        "systemSettings.templateType.invoiceLink"
                    );
                },
            },
            {
                name: "INVOICE_WITH_PDF",
                text: function () {
                    return $translate.instant(
                        "systemSettings.templateType.invoicePdf"
                    );
                },
            },
            {
                name: "INVOICE_WITH_CSV",
                text: function () {
                    return $translate.instant(
                        "systemSettings.templateType.invoiceCsv"
                    );
                },
            },
            {
                name: "INVOICE_WITH_LINK_AND_XLSX",
                text: function () {
                    return $translate.instant(
                        "systemSettings.templateType.invoiceLinkAndXlsx"
                    );
                },
            },
            {
                name: "INVOICE_WITH_PDF_AND_XLSX",
                text: function () {
                    return $translate.instant(
                        "systemSettings.templateType.invoicePdfAndXlsx"
                    );
                },
            },
            {
                name: "INVOICE_WITH_PDF_AND_CSV",
                text: function () {
                    return $translate.instant(
                        "systemSettings.templateType.invoicePdfAndCsv"
                    );
                },
            },
            // {
            //     name: 'CORRECTION_INVOICE',
            //     text: function() {
            //         return $translate.instant('systemSettings.templateType.correctingInvoice');
            //     }
            // },
            {
                name: "INVOICE_TOO_LARGE",
                text: function () {
                    return $translate.instant(
                        "systemSettings.templateType.tooBig"
                    );
                },
            },
        ];

        vm.languages = [
            {
                name: "nl",
                text: function () {
                    return $translate.instant(
                        "systemSettings.templateLanguage.nl"
                    );
                },
            },
            {
                name: "fr",
                text: function () {
                    return $translate.instant(
                        "systemSettings.templateLanguage.fr"
                    );
                },
            },
            {
                name: "en",
                text: function () {
                    return $translate.instant(
                        "systemSettings.templateLanguage.en"
                    );
                },
            },
            {
                name: "de",
                text: function () {
                    return $translate.instant(
                        "systemSettings.templateLanguage.de"
                    );
                },
            },
        ];

        vm.ifContinue = true;
        vm.dhlTemplatesData;
        vm.dhlTemplates = [];
        vm.dhlTitles = [];
        vm.dhlTitlesData = [];
        vm.dhlEmailTempaltes = null;
        vm.dhlClientEmailTempaltesIds = null;
        vm.templatesToSend = [];
        vm.clientId = null;
        vm.dhlClient = null;
        vm.addingEmailTemplateErrorText = null;
        vm.addingEmailTemplateSuccessText = null;
        vm.getDataErrorText = null;
        vm.clientErrorText = null;
        vm.clientSuccessText = null;
        vm.htmlTooltip = false;
        vm.chooseOrDrop = chooseOrDrop;

        vm.clientTemplates = {
            templatesNL: {
                ACCOUNT_OPENED: {
                    name: null,
                    template_id: null,
                },
                EMAIL_ADDED: {
                    name: null,
                    template_id: null,
                },
                REGISTRATION_FAIL: {
                    name: null,
                    template_id: null,
                },
                PASSWORD_CHANGE: {
                    name: null,
                    template_id: null,
                },
                INVOICE_WITH_LINK: {
                    name: null,
                    template_id: null,
                },
                INVOICE_WITH_PDF: {
                    name: null,
                    template_id: null,
                },
                INVOICE_WITH_LINK_AND_XLSX: {
                    name: null,
                    template_id: null,
                },
                INVOICE_WITH_PDF_AND_XLSX: {
                    name: null,
                    template_id: null,
                },
                INVOICE_WITH_PDF_AND_CSV: {
                    name: null,
                    template_id: null,
                },
                // CORRECTION_INVOICE: {
                //     name: null,
                //     template_id: null
                // },
                INVOICE_TOO_LARGE: {
                    name: null,
                    template_id: null,
                },
            },
            titlesNL: {
                ACCOUNT_OPENED: {
                    name: null,
                    template_id: null,
                },
                EMAIL_ADDED: {
                    name: null,
                    template_id: null,
                },
                REGISTRATION_FAIL: {
                    name: null,
                    template_id: null,
                },
                PASSWORD_CHANGE: {
                    name: null,
                    template_id: null,
                },
                INVOICE_WITH_LINK: {
                    name: null,
                    template_id: null,
                },
                INVOICE_WITH_PDF: {
                    name: null,
                    template_id: null,
                },
                INVOICE_WITH_LINK_AND_XLSX: {
                    name: null,
                    template_id: null,
                },
                INVOICE_WITH_PDF_AND_XLSX: {
                    name: null,
                    template_id: null,
                },
                INVOICE_WITH_PDF_AND_CSV: {
                    name: null,
                    template_id: null,
                },
                // CORRECTION_INVOICE: {
                //     name: null,
                //     template_id: null
                // },
                INVOICE_TOO_LARGE: {
                    name: null,
                    template_id: null,
                },
            },
            templatesEN: {
                ACCOUNT_OPENED: {
                    name: null,
                    template_id: null,
                },
                EMAIL_ADDED: {
                    name: null,
                    template_id: null,
                },
                REGISTRATION_FAIL: {
                    name: null,
                    template_id: null,
                },
                PASSWORD_CHANGE: {
                    name: null,
                    template_id: null,
                },
                INVOICE_WITH_LINK: {
                    name: null,
                    template_id: null,
                },
                INVOICE_WITH_PDF: {
                    name: null,
                    template_id: null,
                },
                INVOICE_WITH_LINK_AND_XLSX: {
                    name: null,
                    template_id: null,
                },
                INVOICE_WITH_PDF_AND_XLSX: {
                    name: null,
                    template_id: null,
                },
                INVOICE_WITH_PDF_AND_CSV: {
                    name: null,
                    template_id: null,
                },
                // CORRECTION_INVOICE: {
                //     name: null,
                //     template_id: null
                // },
                INVOICE_TOO_LARGE: {
                    name: null,
                    template_id: null,
                },
            },
            titlesEN: {
                ACCOUNT_OPENED: {
                    name: null,
                    template_id: null,
                },
                EMAIL_ADDED: {
                    name: null,
                    template_id: null,
                },
                REGISTRATION_FAIL: {
                    name: null,
                    template_id: null,
                },
                PASSWORD_CHANGE: {
                    name: null,
                    template_id: null,
                },
                INVOICE_WITH_LINK: {
                    name: null,
                    template_id: null,
                },
                INVOICE_WITH_PDF: {
                    name: null,
                    template_id: null,
                },
                INVOICE_WITH_LINK_AND_XLSX: {
                    name: null,
                    template_id: null,
                },
                INVOICE_WITH_PDF_AND_XLSX: {
                    name: null,
                    template_id: null,
                },
                INVOICE_WITH_PDF_AND_CSV: {
                    name: null,
                    template_id: null,
                },
                // CORRECTION_INVOICE: {
                //     name: null,
                //     template_id: null
                // },
                INVOICE_TOO_LARGE: {
                    name: null,
                    template_id: null,
                },
            },
            templatesFR: {
                ACCOUNT_OPENED: {
                    name: null,
                    template_id: null,
                },
                EMAIL_ADDED: {
                    name: null,
                    template_id: null,
                },
                REGISTRATION_FAIL: {
                    name: null,
                    template_id: null,
                },
                PASSWORD_CHANGE: {
                    name: null,
                    template_id: null,
                },
                INVOICE_WITH_LINK: {
                    name: null,
                    template_id: null,
                },
                INVOICE_WITH_PDF: {
                    name: null,
                    template_id: null,
                },
                INVOICE_WITH_LINK_AND_XLSX: {
                    name: null,
                    template_id: null,
                },
                INVOICE_WITH_PDF_AND_XLSX: {
                    name: null,
                    template_id: null,
                },
                INVOICE_WITH_PDF_AND_CSV: {
                    name: null,
                    template_id: null,
                },
                // CORRECTION_INVOICE: {
                //     name: null,
                //     template_id: null
                // },
                INVOICE_TOO_LARGE: {
                    name: null,
                    template_id: null,
                },
            },
            titlesFR: {
                ACCOUNT_OPENED: {
                    name: null,
                    template_id: null,
                },
                EMAIL_ADDED: {
                    name: null,
                    template_id: null,
                },
                REGISTRATION_FAIL: {
                    name: null,
                    template_id: null,
                },
                PASSWORD_CHANGE: {
                    name: null,
                    template_id: null,
                },
                INVOICE_WITH_LINK: {
                    name: null,
                    template_id: null,
                },
                INVOICE_WITH_PDF: {
                    name: null,
                    template_id: null,
                },
                INVOICE_WITH_LINK_AND_XLSX: {
                    name: null,
                    template_id: null,
                },
                INVOICE_WITH_PDF_AND_XLSX: {
                    name: null,
                    template_id: null,
                },
                INVOICE_WITH_PDF_AND_CSV: {
                    name: null,
                    template_id: null,
                },
                // CORRECTION_INVOICE: {
                //     name: null,
                //     template_id: null
                // },
                INVOICE_TOO_LARGE: {
                    name: null,
                    template_id: null,
                },
            },
            templatesDE: {
                ACCOUNT_OPENED: {
                    name: null,
                    template_id: null,
                },
                EMAIL_ADDED: {
                    name: null,
                    template_id: null,
                },
                REGISTRATION_FAIL: {
                    name: null,
                    template_id: null,
                },
                PASSWORD_CHANGE: {
                    name: null,
                    template_id: null,
                },
                INVOICE_WITH_LINK: {
                    name: null,
                    template_id: null,
                },
                INVOICE_WITH_PDF: {
                    name: null,
                    template_id: null,
                },
                INVOICE_WITH_LINK_AND_XLSX: {
                    name: null,
                    template_id: null,
                },
                INVOICE_WITH_PDF_AND_XLSX: {
                    name: null,
                    template_id: null,
                },
                INVOICE_WITH_PDF_AND_CSV: {
                    name: null,
                    template_id: null,
                },
                // CORRECTION_INVOICE: {
                //     name: null,
                //     template_id: null
                // },
                INVOICE_TOO_LARGE: {
                    name: null,
                    template_id: null,
                },
            },
            titlesDE: {
                ACCOUNT_OPENED: {
                    name: null,
                    template_id: null,
                },
                EMAIL_ADDED: {
                    name: null,
                    template_id: null,
                },
                REGISTRATION_FAIL: {
                    name: null,
                    template_id: null,
                },
                PASSWORD_CHANGE: {
                    name: null,
                    template_id: null,
                },
                INVOICE_WITH_LINK: {
                    name: null,
                    template_id: null,
                },
                INVOICE_WITH_PDF: {
                    name: null,
                    template_id: null,
                },
                INVOICE_WITH_LINK_AND_XLSX: {
                    name: null,
                    template_id: null,
                },
                INVOICE_WITH_PDF_AND_XLSX: {
                    name: null,
                    template_id: null,
                },
                INVOICE_WITH_PDF_AND_CSV: {
                    name: null,
                    template_id: null,
                },
                // CORRECTION_INVOICE: {
                //     name: null,
                //     template_id: null
                // },
                INVOICE_TOO_LARGE: {
                    name: null,
                    template_id: null,
                },
            },
        };

        vm.upload = upload;
        vm.submit = submit;
        vm.chectUniqueNameAndType = chectUniqueNameAndType;
        vm.getTitleTemplates = getTitleTemplates;
        vm.getContentTemplates = getContentTemplates;
        vm.download = download;
        vm.addClientNumber = addClientNumber;
        vm.addInvoiceNumber = addInvoiceNumber;
        vm.addInvoiceDate = addInvoiceDate;
        vm.addNettoValue = addNettoValue;
        vm.checkTemplateTitle = checkTemplateTitle;
        vm.addTemplateTitle = addTemplateTitle;
        vm.clearForms = clearForms;
        vm.getAllEmailTemplates = getAllEmailTemplates;
        vm.getUserTemplatesId = getUserTemplatesId;
        vm.sendNewUserTemplates = sendNewUserTemplates;
        vm.getCommonElementFromLists = getCommonElementFromLists;
        vm.getClientByNumber = getClientByNumber;
        vm.changeActionTypeToText = changeActionTypeToText;
        vm.setTooltip = setTooltip;
        vm.disableTooltip = disableTooltip;

        vm.init = function () {
            vm.interval.get();
            vm.showInterval.get();
            vm.getContentTemplates();
            vm.getTitleTemplates();
            vm.clearForms();
        };

        function clearForms() {
            vm.template = {
                activity: vm.ActivityTypes[0],
                name: null,
                language: vm.languages[0],
            };
            vm.title = {
                activity: vm.ActivityTypes[0],
                name: null,
                text: "",
                language: vm.languages[0],
            };
        }

        vm.interval = {
            current: null,
            new: null,
            measure: "days",
            set: function () {
                var interval = vm.interval.new;
                switch (vm.interval.measure) {
                    case "days":
                        interval *=
                            24 /*h*/ * 60 /*min*/ * 60 /*s*/ * 1000 /*ms*/;
                        break;
                    case "months":
                        interval *=
                            30 /*days*/ *
                            24 /*h*/ *
                            60 /*min*/ *
                            60 /*s*/ *
                            1000 /*ms*/;
                        break;
                    case "years":
                        interval *=
                            365 /*days*/ *
                            24 /*h*/ *
                            60 /*min*/ *
                            60 /*s*/ *
                            1000 /*ms*/;
                        break;
                }
                ConstantsService.set({
                    name: "PASSWORD_CHANGE_INTERVAL",
                    value: interval,
                }).then(function (response) {
                    vm.interval.get();
                });
            },
            get: function () {
                ConstantsService.get("PASSWORD_CHANGE_INTERVAL").then(function (
                    response
                ) {
                    var interval = response.data.value;
                    //Convert to days
                    interval =
                        interval /
                        1000 /*ms*/ /
                        60 /*s*/ /
                        60 /*min*/ /
                        24 /*h*/;
                    vm.interval.current = interval;
                });
            },
        };

        vm.showInterval = {
            current: null,
            new: null,
            measure: "days",
            set: function () {
                var interval = vm.showInterval.new;
                switch (vm.showInterval.measure) {
                    case "days":
                        interval *=
                            24 /*h*/ * 60 /*min*/ * 60 /*s*/ * 1000 /*ms*/;
                        break;
                    case "months":
                        interval *=
                            30 /*days*/ *
                            24 /*h*/ *
                            60 /*min*/ *
                            60 /*s*/ *
                            1000 /*ms*/;
                        break;
                    case "years":
                        interval *=
                            365 /*days*/ *
                            24 /*h*/ *
                            60 /*min*/ *
                            60 /*s*/ *
                            1000 /*ms*/;
                        break;
                }
                ConstantsService.set({
                    name: "PASSWORD_SHOW_CHANGE_INTERVAL",
                    value: interval,
                }).then(function (response) {
                    vm.showInterval.get();
                });
            },
            get: function () {
                ConstantsService.get("PASSWORD_SHOW_CHANGE_INTERVAL").then(
                    function (response) {
                        var interval = response.data.value;
                        //Convert to days
                        interval =
                            interval /
                            1000 /*ms*/ /
                            60 /*s*/ /
                            60 /*min*/ /
                            24 /*h*/;
                        vm.showInterval.current = interval;
                    }
                );
            },
        };

        function submit(file) {
            if (typeof file !== "undefined" && file) {
                vm.upload(file);
            } else {
                vm.addingEmailTemplateErrorText = $translate.instant(
                    "systemSettings.messages.choseFile"
                );
                vm.addingEmailTemplateSuccessText = null;
            }
        }

        // upload on file select or drop
        function upload(file) {
            Upload.upload({
                url: $rootScope.API_URL + "api/beneluxTemplates/upload",
                fields: {
                    name: vm.template.name,
                    type: vm.template.activity,
                    language: vm.template.language,
                },
                file: file,
            }).then(
                function (resp) {
                    vm.getContentTemplates();
                    vm.clearForms();
                    vm.addingEmailTemplateSuccessText = $translate.instant(
                        "systemSettings.messages.templateSuccess"
                    );
                    vm.addingEmailTemplateErrorText = null;
                },
                function (resp) {
                    vm.addingEmailTemplateErrorText =
                        $translate.instant("error.occurred");
                    vm.addingEmailTemplateSuccessText = null;
                }
            );
        }

        function chectUniqueNameAndType(file, size) {
            MailTemplatesService.checkUnique(
                vm.template.name,
                vm.template.activity,
                vm.template.language,
                "EMAIL_CONTENT"
            )
                .then(function (response) {
                    vm.submit(file);
                })
                .catch(function (response) {
                    if (!response.data.unique) {
                        var modalInstance = $uibModal.open({
                            animation: true,
                            ariaLabelledBy: "modal-title",
                            ariaDescribedBy: "modal-body",
                            templateUrl:
                                "app/views/system-settings/template-upload-modal/system-settings.modal.html",
                            controller: "SystemSettingsModalController",
                            controllerAs: "vm",
                            size: size,
                        });

                        modalInstance.result.then(function (go) {
                            if (go) {
                                vm.submit(file);
                            }
                        });
                    } else {
                        vm.addingEmailTemplateErrorText =
                            $translate.instant("error.occurred");
                        vm.addingEmailTemplateSuccessText = null;
                    }
                });
        }

        function getEntityTemplates(data) {
            switch (Principal.getCurrentEntity()) {
                case "BE":
                    if (data._embedded.beTemplates != null) {
                        return data._embedded.beTemplates;
                    }
                    break;
                case "NL":
                    if (data._embedded.nlTemplates != null) {
                        return data._embedded.nlTemplates;
                    }
                    break;

                case "EC":
                    if (data._embedded.ecTemplates != null) {
                        return data._embedded.ecTemplates;
                    }
                    break;
            }
        }

        function getContentTemplates() {
            MailTemplatesService.getTemplatesByType("EMAIL_CONTENT")
                .then(function (response) {
                    //add 'BENELUXUI' to url if missing
                    fixTemplatesLinks(response);

                    vm.dhlTemplatesData = response.data;
                    vm.dhlTemplates = getEntityTemplates(response.data);
                })
                .catch(function (response) {
                    vm.getDataErrorText = $translate.instant(
                        "systemSettings.dataError"
                    );
                });
        }

        function getTitleTemplates() {
            MailTemplatesService.getTemplatesByType("EMAIL_TITLE")
                .then(function (response) {
                    //add 'BENELUXUI' to url if missing
                    fixTemplatesLinks(response);

                    vm.dhlTitlesData = response.data;
                    vm.dhlTitles = getEntityTemplates(response.data);
                })
                .catch(function (response) {
                    vm.getDataErrorText = $translate.instant(
                        "systemSettings.dataError"
                    );
                });
        }

        function download(name, type, language, filename) {
            MailTemplatesService.download(name, type, language)
                .then(function (response) {
                    FileSaver.saveAs(
                        new Blob([response.data], { type: "text/html" }),
                        filename
                    );
                })
                .catch(function (response) {
                    //blad pobrania danych
                });
        }

        function addClientNumber() {
            if (vm.title.text) {
                vm.title.text = [
                    vm.title.text.slice(
                        0,
                        $("#titleArea").prop("selectionStart")
                    ),
                    "{{clientNumber}}",
                    vm.title.text.slice($("#titleArea").prop("selectionStart")),
                ].join("");
            } else {
                vm.title.text = "{{clientNumber}}";
            }
        }

        function addInvoiceNumber() {
            if (vm.title.text) {
                vm.title.text = [
                    vm.title.text.slice(
                        0,
                        $("#titleArea").prop("selectionStart")
                    ),
                    "{{invoiceNumber}}",
                    vm.title.text.slice($("#titleArea").prop("selectionStart")),
                ].join("");
            } else {
                vm.title.text = "{{invoiceNumber}}";
            }
        }

        function addInvoiceDate() {
            if (vm.title.text) {
                vm.title.text = [
                    vm.title.text.slice(
                        0,
                        $("#titleArea").prop("selectionStart")
                    ),
                    "{{invoiceDate}}",
                    vm.title.text.slice($("#titleArea").prop("selectionStart")),
                ].join("");
            } else {
                vm.title.text = "{{invoiceDate}}";
            }
        }

        function addNettoValue() {
            if (vm.title.text) {
                vm.title.text = [
                    vm.title.text.slice(
                        0,
                        $("#titleArea").prop("selectionStart")
                    ),
                    "{{nettoValue}}",
                    vm.title.text.slice($("#titleArea").prop("selectionStart")),
                ].join("");
            } else {
                vm.title.text = "{{nettoValue}}";
            }
        }

        function checkTemplateTitle(size) {
            MailTemplatesService.checkUnique(
                vm.title.name,
                vm.title.activity,
                vm.title.language,
                "EMAIL_TITLE"
            )
                .then(function (response) {
                    vm.addTemplateTitle(
                        vm.title.name,
                        vm.title.activity,
                        vm.title.text,
                        vm.title.language
                    );
                })
                .catch(function (response) {
                    if (response.data.unique == false) {
                        var modalInstance = $uibModal.open({
                            animation: true,
                            ariaLabelledBy: "modal-title",
                            ariaDescribedBy: "modal-body",
                            templateUrl:
                                "app/views/system-settings/template-upload-modal/system-settings.modal.html",
                            controller: "SystemSettingsModalController",
                            controllerAs: "vm",
                            size: size,
                        });

                        modalInstance.result.then(function (go) {
                            if (go) {
                                vm.addTemplateTitle(
                                    vm.title.name,
                                    vm.title.activity,
                                    vm.title.text,
                                    vm.title.language
                                );
                            }
                        });
                    } else {
                        vm.addingEmailTitleSuccessText = null;
                        vm.addingEmailTitleErrorText =
                            $translate.instant("error.occurred");
                    }
                });
        }

        function addTemplateTitle(name, activity, text, language) {
            MailTemplatesService.addTemplateTitle(
                name,
                activity,
                text,
                language
            )
                .then(function (response) {
                    vm.getTitleTemplates();
                    vm.clearForms();
                    vm.addingEmailTitleSuccessText = $translate.instant(
                        "systemSettings.messages.templateSuccess"
                    );
                    vm.addingEmailTitleErrorText = null;
                })
                .catch(function (response) {
                    vm.addingEmailTitleSuccessText = null;
                    vm.addingEmailTitleErrorText =
                        $translate.instant("error.occurred");
                });
        }

        function fixURL(url) {
            if ($rootScope.API_URL === API_URL.BE) {
                url = url.replace(
                    "https://billing.dhlparcel.nl",
                    "https://billing.dhlparcel.be"
                );
            } else {
                url = url.replace(
                    "https://billing.dhlparcel.be",
                    "https://billing.dhlparcel.nl"
                );
            }
            if (
                url != null &&
                !(url.indexOf("BENELUXUI") !== -1) &&
                url.indexOf("dev") !== -1
            ) {
                return url.replace("edistrada2", "BENELUXUI/edistrada2");
            } else {
                return url;
            }
        }

        function fixTemplatesLinks(response) {
            if (response.data._links["self"])
                response.data._links["self"].href = fixURL(
                    response.data._links["self"].href
                );
            if (response.data._links["first"])
                response.data._links["first"].href = fixURL(
                    response.data._links["first"].href
                );
            if (response.data._links["next"])
                response.data._links["next"].href = fixURL(
                    response.data._links["next"].href
                );
            if (response.data._links["last"])
                response.data._links["last"].href = fixURL(
                    response.data._links["last"].href
                );
            if (response.data._links["prev"])
                response.data._links["prev"].href = fixURL(
                    response.data._links["prev"].href
                );
        }

        function getClientByNumber() {
            ClientsService.findByClientNumber(vm.clientId)
                .then(function (response) {
                    //add 'BENELUXUI' to url if missing
                    if (response.data._links["self"])
                        response.data._links["self"].href = fixURL(
                            response.data._links["self"].href
                        );
                    if (response.data._links["nlClient"])
                        response.data._links["nlClient"].href = fixURL(
                            response.data._links["nlClient"].href
                        );
                    if (response.data._links["beneluxTemplates"])
                        response.data._links["beneluxTemplates"].href = fixURL(
                            response.data._links["beneluxTemplates"].href
                        );
                    if (response.data._links["ftpConfiguration"])
                        response.data._links["ftpConfiguration"].href = fixURL(
                            response.data._links["ftpConfiguration"].href
                        );
                    if (response.data._links["beneluxUsers"])
                        response.data._links["beneluxUsers"].href = fixURL(
                            response.data._links["beneluxUsers"].href
                        );

                    vm.dhlClient = response.data;
                    vm.getAllEmailTemplates();
                })
                .catch(function (response) {
                    vm.dhlClient = null;
                    if (response.status == 404) {
                        vm.clientErrorText = $translate.instant(
                            "systemSettings.messages.noClientFound"
                        );
                        vm.clientSuccessText = null;
                    } else {
                        vm.clientErrorText =
                            $translate.instant("error.occurred");
                        vm.clientSuccessText = null;
                    }
                });
        }

        function getAllEmailTemplates() {
            MailTemplatesService.getAllTemplates()
                .then(function (response) {
                    if (response.data._embedded != null) {
                        vm.dhlEmailTempaltes =
                            response.data._embedded[
                                Principal.getCurrentEntityLowerCase() +
                                    "Templates"
                            ];
                        vm.getUserTemplatesId();
                    } else {
                        vm.clientErrorText = $translate.instant(
                            "systemSettings.messages.templatesDownloadFailed"
                        );
                        vm.clientSuccessText = null;
                    }
                })
                .catch(function (response) {
                    vm.clientErrorText = $translate.instant(
                        "systemSettings.messages.templatesDownloadFailed"
                    );
                    vm.clientSuccessText = null;
                });
        }

        function getUserTemplatesId() {
            ClientsService.getClientTemplates(vm.dhlClient.ediClientId)
                .then(function (response) {
                    vm.dhlClientEmailTempaltesIds = response.data;

                    var id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_TITLE",
                                actionType: "ACCOUNT_OPENED",
                                language: "nl",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.titlesNL.ACCOUNT_OPENED = filterFilter(
                        vm.dhlEmailTempaltes,
                        { ediTemplateId: id }
                    )[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_TITLE",
                                actionType: "EMAIL_ADDED",
                                language: "nl",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.titlesNL.EMAIL_ADDED = filterFilter(
                        vm.dhlEmailTempaltes,
                        { ediTemplateId: id }
                    )[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_TITLE",
                                actionType: "REGISTRATION_FAIL",
                                language: "nl",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.titlesNL.REGISTRATION_FAIL =
                        filterFilter(vm.dhlEmailTempaltes, {
                            ediTemplateId: id,
                        })[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_TITLE",
                                actionType: "PASSWORD_CHANGE",
                                language: "nl",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.titlesNL.PASSWORD_CHANGE = filterFilter(
                        vm.dhlEmailTempaltes,
                        { ediTemplateId: id }
                    )[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_TITLE",
                                actionType: "INVOICE_WITH_LINK",
                                language: "nl",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.titlesNL.INVOICE_WITH_LINK =
                        filterFilter(vm.dhlEmailTempaltes, {
                            ediTemplateId: id,
                        })[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_TITLE",
                                actionType: "INVOICE_WITH_PDF",
                                language: "nl",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.titlesNL.INVOICE_WITH_PDF = filterFilter(
                        vm.dhlEmailTempaltes,
                        { ediTemplateId: id }
                    )[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_TITLE",
                                actionType: "INVOICE_WITH_LINK_AND_XLSX",
                                language: "nl",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.titlesNL.INVOICE_WITH_LINK_AND_XLSX =
                        filterFilter(vm.dhlEmailTempaltes, {
                            ediTemplateId: id,
                        })[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_TITLE",
                                actionType: "INVOICE_WITH_PDF_AND_XLSX",
                                language: "nl",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.titlesNL.INVOICE_WITH_PDF_AND_XLSX =
                        filterFilter(vm.dhlEmailTempaltes, {
                            ediTemplateId: id,
                        })[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_TITLE",
                                actionType: "INVOICE_WITH_PDF_AND_CSV",
                                language: "nl",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.titlesNL.INVOICE_WITH_PDF_AND_CSV =
                        filterFilter(vm.dhlEmailTempaltes, {
                            ediTemplateId: id,
                        })[0];

                    // id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'CORRECTION_INVOICE', language: 'nl' }, true), vm.dhlClientEmailTempaltesIds);
                    // vm.clientTemplates.titlesNL.CORRECTION_INVOICE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_TITLE",
                                actionType: "INVOICE_TOO_LARGE",
                                language: "nl",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.titlesNL.INVOICE_TOO_LARGE =
                        filterFilter(vm.dhlEmailTempaltes, {
                            ediTemplateId: id,
                        })[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_CONTENT",
                                actionType: "ACCOUNT_OPENED",
                                language: "nl",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.templatesNL.ACCOUNT_OPENED =
                        filterFilter(vm.dhlEmailTempaltes, {
                            ediTemplateId: id,
                        })[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_CONTENT",
                                actionType: "EMAIL_ADDED",
                                language: "nl",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.templatesNL.EMAIL_ADDED = filterFilter(
                        vm.dhlEmailTempaltes,
                        { ediTemplateId: id }
                    )[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_CONTENT",
                                actionType: "REGISTRATION_FAIL",
                                language: "nl",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.templatesNL.REGISTRATION_FAIL =
                        filterFilter(vm.dhlEmailTempaltes, {
                            ediTemplateId: id,
                        })[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_CONTENT",
                                actionType: "PASSWORD_CHANGE",
                                language: "nl",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.templatesNL.PASSWORD_CHANGE =
                        filterFilter(vm.dhlEmailTempaltes, {
                            ediTemplateId: id,
                        })[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_CONTENT",
                                actionType: "INVOICE_WITH_LINK",
                                language: "nl",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.templatesNL.INVOICE_WITH_LINK =
                        filterFilter(vm.dhlEmailTempaltes, {
                            ediTemplateId: id,
                        })[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_CONTENT",
                                actionType: "INVOICE_WITH_PDF",
                                language: "nl",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.templatesNL.INVOICE_WITH_PDF =
                        filterFilter(vm.dhlEmailTempaltes, {
                            ediTemplateId: id,
                        })[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_CONTENT",
                                actionType: "INVOICE_WITH_LINK_AND_XLSX",
                                language: "nl",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.templatesNL.INVOICE_WITH_LINK_AND_XLSX =
                        filterFilter(vm.dhlEmailTempaltes, {
                            ediTemplateId: id,
                        })[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_CONTENT",
                                actionType: "INVOICE_WITH_PDF_AND_XLSX",
                                language: "nl",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.templatesNL.INVOICE_WITH_PDF_AND_XLSX =
                        filterFilter(vm.dhlEmailTempaltes, {
                            ediTemplateId: id,
                        })[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_CONTENT",
                                actionType: "INVOICE_WITH_PDF_AND_CSV",
                                language: "nl",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.templatesNL.INVOICE_WITH_PDF_AND_CSV =
                        filterFilter(vm.dhlEmailTempaltes, {
                            ediTemplateId: id,
                        })[0];

                    // id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'CORRECTION_INVOICE', language: 'nl' }, true), vm.dhlClientEmailTempaltesIds);
                    // vm.clientTemplates.templatesNL.CORRECTION_INVOICE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_CONTENT",
                                actionType: "INVOICE_TOO_LARGE",
                                language: "nl",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.templatesNL.INVOICE_TOO_LARGE =
                        filterFilter(vm.dhlEmailTempaltes, {
                            ediTemplateId: id,
                        })[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_TITLE",
                                actionType: "ACCOUNT_OPENED",
                                language: "en",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.titlesEN.ACCOUNT_OPENED = filterFilter(
                        vm.dhlEmailTempaltes,
                        { ediTemplateId: id }
                    )[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_TITLE",
                                actionType: "EMAIL_ADDED",
                                language: "en",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.titlesEN.EMAIL_ADDED = filterFilter(
                        vm.dhlEmailTempaltes,
                        { ediTemplateId: id }
                    )[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_TITLE",
                                actionType: "REGISTRATION_FAIL",
                                language: "en",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.titlesEN.REGISTRATION_FAIL =
                        filterFilter(vm.dhlEmailTempaltes, {
                            ediTemplateId: id,
                        })[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_TITLE",
                                actionType: "PASSWORD_CHANGE",
                                language: "en",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.titlesEN.PASSWORD_CHANGE = filterFilter(
                        vm.dhlEmailTempaltes,
                        { ediTemplateId: id }
                    )[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_TITLE",
                                actionType: "INVOICE_WITH_LINK",
                                language: "en",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.titlesEN.INVOICE_WITH_LINK =
                        filterFilter(vm.dhlEmailTempaltes, {
                            ediTemplateId: id,
                        })[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_TITLE",
                                actionType: "INVOICE_WITH_PDF",
                                language: "en",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.titlesEN.INVOICE_WITH_PDF = filterFilter(
                        vm.dhlEmailTempaltes,
                        { ediTemplateId: id }
                    )[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_TITLE",
                                actionType: "INVOICE_WITH_LINK_AND_XLSX",
                                language: "en",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.titlesEN.INVOICE_WITH_LINK_AND_XLSX =
                        filterFilter(vm.dhlEmailTempaltes, {
                            ediTemplateId: id,
                        })[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_TITLE",
                                actionType: "INVOICE_WITH_PDF_AND_XLSX",
                                language: "en",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.titlesEN.INVOICE_WITH_PDF_AND_XLSX =
                        filterFilter(vm.dhlEmailTempaltes, {
                            ediTemplateId: id,
                        })[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_TITLE",
                                actionType: "INVOICE_WITH_PDF_AND_CSV",
                                language: "en",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.titlesEN.INVOICE_WITH_PDF_AND_CSV =
                        filterFilter(vm.dhlEmailTempaltes, {
                            ediTemplateId: id,
                        })[0];

                    // id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'CORRECTION_INVOICE', language: 'en' }, true), vm.dhlClientEmailTempaltesIds);
                    // vm.clientTemplates.titlesEN.CORRECTION_INVOICE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_TITLE",
                                actionType: "INVOICE_TOO_LARGE",
                                language: "en",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.titlesEN.INVOICE_TOO_LARGE =
                        filterFilter(vm.dhlEmailTempaltes, {
                            ediTemplateId: id,
                        })[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_CONTENT",
                                actionType: "ACCOUNT_OPENED",
                                language: "en",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.templatesEN.ACCOUNT_OPENED =
                        filterFilter(vm.dhlEmailTempaltes, {
                            ediTemplateId: id,
                        })[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_CONTENT",
                                actionType: "EMAIL_ADDED",
                                language: "en",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.templatesEN.EMAIL_ADDED = filterFilter(
                        vm.dhlEmailTempaltes,
                        { ediTemplateId: id }
                    )[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_CONTENT",
                                actionType: "REGISTRATION_FAIL",
                                language: "en",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.templatesEN.REGISTRATION_FAIL =
                        filterFilter(vm.dhlEmailTempaltes, {
                            ediTemplateId: id,
                        })[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_CONTENT",
                                actionType: "PASSWORD_CHANGE",
                                language: "en",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.templatesEN.PASSWORD_CHANGE =
                        filterFilter(vm.dhlEmailTempaltes, {
                            ediTemplateId: id,
                        })[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_CONTENT",
                                actionType: "INVOICE_WITH_LINK",
                                language: "en",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.templatesEN.INVOICE_WITH_LINK =
                        filterFilter(vm.dhlEmailTempaltes, {
                            ediTemplateId: id,
                        })[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_CONTENT",
                                actionType: "INVOICE_WITH_PDF",
                                language: "en",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.templatesEN.INVOICE_WITH_PDF =
                        filterFilter(vm.dhlEmailTempaltes, {
                            ediTemplateId: id,
                        })[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_CONTENT",
                                actionType: "INVOICE_WITH_LINK_AND_XLSX",
                                language: "en",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.templatesEN.INVOICE_WITH_LINK_AND_XLSX =
                        filterFilter(vm.dhlEmailTempaltes, {
                            ediTemplateId: id,
                        })[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_CONTENT",
                                actionType: "INVOICE_WITH_PDF_AND_XLSX",
                                language: "en",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.templatesEN.INVOICE_WITH_PDF_AND_XLSX =
                        filterFilter(vm.dhlEmailTempaltes, {
                            ediTemplateId: id,
                        })[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_CONTENT",
                                actionType: "INVOICE_WITH_PDF_AND_CSV",
                                language: "en",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.templatesEN.INVOICE_WITH_PDF_AND_CSV =
                        filterFilter(vm.dhlEmailTempaltes, {
                            ediTemplateId: id,
                        })[0];

                    // id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'CORRECTION_INVOICE', language: 'en' }, true), vm.dhlClientEmailTempaltesIds);
                    // vm.clientTemplates.templatesEN.CORRECTION_INVOICE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_CONTENT",
                                actionType: "INVOICE_TOO_LARGE",
                                language: "en",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.templatesEN.INVOICE_TOO_LARGE =
                        filterFilter(vm.dhlEmailTempaltes, {
                            ediTemplateId: id,
                        })[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_TITLE",
                                actionType: "ACCOUNT_OPENED",
                                language: "fr",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.titlesFR.ACCOUNT_OPENED = filterFilter(
                        vm.dhlEmailTempaltes,
                        { ediTemplateId: id }
                    )[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_TITLE",
                                actionType: "EMAIL_ADDED",
                                language: "fr",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.titlesFR.EMAIL_ADDED = filterFilter(
                        vm.dhlEmailTempaltes,
                        { ediTemplateId: id }
                    )[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_TITLE",
                                actionType: "REGISTRATION_FAIL",
                                language: "fr",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.titlesFR.REGISTRATION_FAIL =
                        filterFilter(vm.dhlEmailTempaltes, {
                            ediTemplateId: id,
                        })[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_TITLE",
                                actionType: "PASSWORD_CHANGE",
                                language: "fr",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.titlesFR.PASSWORD_CHANGE = filterFilter(
                        vm.dhlEmailTempaltes,
                        { ediTemplateId: id }
                    )[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_TITLE",
                                actionType: "INVOICE_WITH_LINK",
                                language: "fr",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.titlesFR.INVOICE_WITH_LINK =
                        filterFilter(vm.dhlEmailTempaltes, {
                            ediTemplateId: id,
                        })[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_TITLE",
                                actionType: "INVOICE_WITH_PDF",
                                language: "fr",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.titlesFR.INVOICE_WITH_PDF = filterFilter(
                        vm.dhlEmailTempaltes,
                        { ediTemplateId: id }
                    )[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_TITLE",
                                actionType: "INVOICE_WITH_LINK_AND_XLSX",
                                language: "fr",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.titlesFR.INVOICE_WITH_LINK_AND_XLSX =
                        filterFilter(vm.dhlEmailTempaltes, {
                            ediTemplateId: id,
                        })[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_TITLE",
                                actionType: "INVOICE_WITH_PDF_AND_XLSX",
                                language: "fr",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.titlesFR.INVOICE_WITH_PDF_AND_XLSX =
                        filterFilter(vm.dhlEmailTempaltes, {
                            ediTemplateId: id,
                        })[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_TITLE",
                                actionType: "INVOICE_WITH_PDF_AND_CSV",
                                language: "fr",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.titlesFR.INVOICE_WITH_PDF_AND_CSV =
                        filterFilter(vm.dhlEmailTempaltes, {
                            ediTemplateId: id,
                        })[0];

                    // id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'CORRECTION_INVOICE', language: 'fr' }, true), vm.dhlClientEmailTempaltesIds);
                    // vm.clientTemplates.titlesFR.CORRECTION_INVOICE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_TITLE",
                                actionType: "INVOICE_TOO_LARGE",
                                language: "fr",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.titlesFR.INVOICE_TOO_LARGE =
                        filterFilter(vm.dhlEmailTempaltes, {
                            ediTemplateId: id,
                        })[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_CONTENT",
                                actionType: "ACCOUNT_OPENED",
                                language: "fr",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.templatesFR.ACCOUNT_OPENED =
                        filterFilter(vm.dhlEmailTempaltes, {
                            ediTemplateId: id,
                        })[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_CONTENT",
                                actionType: "EMAIL_ADDED",
                                language: "fr",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.templatesFR.EMAIL_ADDED = filterFilter(
                        vm.dhlEmailTempaltes,
                        { ediTemplateId: id }
                    )[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_CONTENT",
                                actionType: "REGISTRATION_FAIL",
                                language: "fr",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.templatesFR.REGISTRATION_FAIL =
                        filterFilter(vm.dhlEmailTempaltes, {
                            ediTemplateId: id,
                        })[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_CONTENT",
                                actionType: "PASSWORD_CHANGE",
                                language: "fr",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.templatesFR.PASSWORD_CHANGE =
                        filterFilter(vm.dhlEmailTempaltes, {
                            ediTemplateId: id,
                        })[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_CONTENT",
                                actionType: "INVOICE_WITH_LINK",
                                language: "fr",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.templatesFR.INVOICE_WITH_LINK =
                        filterFilter(vm.dhlEmailTempaltes, {
                            ediTemplateId: id,
                        })[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_CONTENT",
                                actionType: "INVOICE_WITH_PDF",
                                language: "fr",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.templatesFR.INVOICE_WITH_PDF =
                        filterFilter(vm.dhlEmailTempaltes, {
                            ediTemplateId: id,
                        })[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_CONTENT",
                                actionType: "INVOICE_WITH_LINK_AND_XLSX",
                                language: "fr",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.templatesFR.INVOICE_WITH_LINK_AND_XLSX =
                        filterFilter(vm.dhlEmailTempaltes, {
                            ediTemplateId: id,
                        })[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_CONTENT",
                                actionType: "INVOICE_WITH_PDF_AND_XLSX",
                                language: "fr",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.templatesFR.INVOICE_WITH_PDF_AND_XLSX =
                        filterFilter(vm.dhlEmailTempaltes, {
                            ediTemplateId: id,
                        })[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_CONTENT",
                                actionType: "INVOICE_WITH_PDF_AND_CSV",
                                language: "fr",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.templatesFR.INVOICE_WITH_PDF_AND_CSV =
                        filterFilter(vm.dhlEmailTempaltes, {
                            ediTemplateId: id,
                        })[0];

                    // id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'CORRECTION_INVOICE', language: 'fr' }, true), vm.dhlClientEmailTempaltesIds);
                    // vm.clientTemplates.templatesFR.CORRECTION_INVOICE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_CONTENT",
                                actionType: "INVOICE_TOO_LARGE",
                                language: "fr",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.templatesFR.INVOICE_TOO_LARGE =
                        filterFilter(vm.dhlEmailTempaltes, {
                            ediTemplateId: id,
                        })[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_TITLE",
                                actionType: "ACCOUNT_OPENED",
                                language: "de",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.titlesDE.ACCOUNT_OPENED = filterFilter(
                        vm.dhlEmailTempaltes,
                        { ediTemplateId: id }
                    )[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_TITLE",
                                actionType: "EMAIL_ADDED",
                                language: "de",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.titlesDE.EMAIL_ADDED = filterFilter(
                        vm.dhlEmailTempaltes,
                        { ediTemplateId: id }
                    )[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_TITLE",
                                actionType: "REGISTRATION_FAIL",
                                language: "de",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.titlesDE.REGISTRATION_FAIL =
                        filterFilter(vm.dhlEmailTempaltes, {
                            ediTemplateId: id,
                        })[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_TITLE",
                                actionType: "PASSWORD_CHANGE",
                                language: "de",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.titlesDE.PASSWORD_CHANGE = filterFilter(
                        vm.dhlEmailTempaltes,
                        { ediTemplateId: id }
                    )[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_TITLE",
                                actionType: "INVOICE_WITH_LINK",
                                language: "de",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.titlesDE.INVOICE_WITH_LINK =
                        filterFilter(vm.dhlEmailTempaltes, {
                            ediTemplateId: id,
                        })[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_TITLE",
                                actionType: "INVOICE_WITH_PDF",
                                language: "de",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.titlesDE.INVOICE_WITH_PDF = filterFilter(
                        vm.dhlEmailTempaltes,
                        { ediTemplateId: id }
                    )[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_TITLE",
                                actionType: "INVOICE_WITH_LINK_AND_XLSX",
                                language: "de",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.titlesDE.INVOICE_WITH_LINK_AND_XLSX =
                        filterFilter(vm.dhlEmailTempaltes, {
                            ediTemplateId: id,
                        })[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_TITLE",
                                actionType: "INVOICE_WITH_PDF_AND_XLSX",
                                language: "de",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.titlesDE.INVOICE_WITH_PDF_AND_XLSX =
                        filterFilter(vm.dhlEmailTempaltes, {
                            ediTemplateId: id,
                        })[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_TITLE",
                                actionType: "INVOICE_WITH_PDF_AND_CSV",
                                language: "de",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.titlesDE.INVOICE_WITH_PDF_AND_CSV =
                        filterFilter(vm.dhlEmailTempaltes, {
                            ediTemplateId: id,
                        })[0];

                    // id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'CORRECTION_INVOICE', language: 'de' }, true), vm.dhlClientEmailTempaltesIds);
                    // vm.clientTemplates.titlesDE.CORRECTION_INVOICE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_TITLE",
                                actionType: "INVOICE_TOO_LARGE",
                                language: "de",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.titlesDE.INVOICE_TOO_LARGE =
                        filterFilter(vm.dhlEmailTempaltes, {
                            ediTemplateId: id,
                        })[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_CONTENT",
                                actionType: "ACCOUNT_OPENED",
                                language: "de",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.templatesDE.ACCOUNT_OPENED =
                        filterFilter(vm.dhlEmailTempaltes, {
                            ediTemplateId: id,
                        })[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_CONTENT",
                                actionType: "EMAIL_ADDED",
                                language: "de",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.templatesDE.EMAIL_ADDED = filterFilter(
                        vm.dhlEmailTempaltes,
                        { ediTemplateId: id }
                    )[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_CONTENT",
                                actionType: "REGISTRATION_FAIL",
                                language: "de",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.templatesDE.REGISTRATION_FAIL =
                        filterFilter(vm.dhlEmailTempaltes, {
                            ediTemplateId: id,
                        })[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_CONTENT",
                                actionType: "PASSWORD_CHANGE",
                                language: "de",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.templatesDE.PASSWORD_CHANGE =
                        filterFilter(vm.dhlEmailTempaltes, {
                            ediTemplateId: id,
                        })[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_CONTENT",
                                actionType: "INVOICE_WITH_LINK",
                                language: "de",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.templatesDE.INVOICE_WITH_LINK =
                        filterFilter(vm.dhlEmailTempaltes, {
                            ediTemplateId: id,
                        })[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_CONTENT",
                                actionType: "INVOICE_WITH_PDF",
                                language: "de",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.templatesDE.INVOICE_WITH_PDF =
                        filterFilter(vm.dhlEmailTempaltes, {
                            ediTemplateId: id,
                        })[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_CONTENT",
                                actionType: "INVOICE_WITH_LINK_AND_XLSX",
                                language: "de",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.templatesDE.INVOICE_WITH_LINK_AND_XLSX =
                        filterFilter(vm.dhlEmailTempaltes, {
                            ediTemplateId: id,
                        })[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_CONTENT",
                                actionType: "INVOICE_WITH_PDF_AND_XLSX",
                                language: "de",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.templatesDE.INVOICE_WITH_PDF_AND_XLSX =
                        filterFilter(vm.dhlEmailTempaltes, {
                            ediTemplateId: id,
                        })[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_CONTENT",
                                actionType: "INVOICE_WITH_PDF_AND_CSV",
                                language: "de",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.templatesDE.INVOICE_WITH_PDF_AND_CSV =
                        filterFilter(vm.dhlEmailTempaltes, {
                            ediTemplateId: id,
                        })[0];

                    // id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'CORRECTION_INVOICE', language: 'de' }, true), vm.dhlClientEmailTempaltesIds);
                    // vm.clientTemplates.templatesDE.CORRECTION_INVOICE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(
                        filterFilter(
                            vm.dhlEmailTempaltes,
                            {
                                type: "EMAIL_CONTENT",
                                actionType: "INVOICE_TOO_LARGE",
                                language: "de",
                            },
                            true
                        ),
                        vm.dhlClientEmailTempaltesIds
                    );
                    vm.clientTemplates.templatesDE.INVOICE_TOO_LARGE =
                        filterFilter(vm.dhlEmailTempaltes, {
                            ediTemplateId: id,
                        })[0];
                })
                .catch(function (response) {
                    vm.clientErrorText = $translate.instant(
                        "systemSettings.messages.templatesDownloadFailed"
                    );
                    vm.clientSuccessText = null;
                });
        }

        function getCommonElementFromLists(list1, list2) {
            var id;
            angular.forEach(list1, function (value, key) {
                if (list2.indexOf(value.ediTemplateId) > -1) {
                    id = value.ediTemplateId;
                }
            });
            return id;
        }

        //client + listofid
        function sendNewUserTemplates() {
            vm.templatesToSend = [];

            vm.templatesToSend.push(
                vm.clientTemplates.titlesNL.ACCOUNT_OPENED.ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.titlesNL.EMAIL_ADDED.ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.titlesNL.REGISTRATION_FAIL.ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.titlesNL.PASSWORD_CHANGE.ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.titlesNL.INVOICE_WITH_LINK.ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.titlesNL.INVOICE_WITH_PDF.ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.titlesNL.INVOICE_WITH_LINK_AND_XLSX
                    .ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.titlesNL.INVOICE_WITH_PDF_AND_XLSX
                    .ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.titlesNL.INVOICE_WITH_PDF_AND_CSV
                    .ediTemplateId
            );
            // vm.templatesToSend.push(vm.clientTemplates.titlesNL.CORRECTION_INVOICE.ediTemplateId);
            vm.templatesToSend.push(
                vm.clientTemplates.titlesNL.INVOICE_TOO_LARGE.ediTemplateId
            );

            vm.templatesToSend.push(
                vm.clientTemplates.templatesNL.ACCOUNT_OPENED.ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.templatesNL.EMAIL_ADDED.ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.templatesNL.REGISTRATION_FAIL.ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.templatesNL.PASSWORD_CHANGE.ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.templatesNL.INVOICE_WITH_LINK.ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.templatesNL.INVOICE_WITH_PDF.ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.templatesNL.INVOICE_WITH_LINK_AND_XLSX
                    .ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.templatesNL.INVOICE_WITH_PDF_AND_XLSX
                    .ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.templatesNL.INVOICE_WITH_PDF_AND_CSV
                    .ediTemplateId
            );
            // vm.templatesToSend.push(vm.clientTemplates.templatesNL.CORRECTION_INVOICE.ediTemplateId);
            vm.templatesToSend.push(
                vm.clientTemplates.templatesNL.INVOICE_TOO_LARGE.ediTemplateId
            );

            vm.templatesToSend.push(
                vm.clientTemplates.titlesEN.ACCOUNT_OPENED.ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.titlesEN.EMAIL_ADDED.ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.titlesEN.REGISTRATION_FAIL.ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.titlesEN.PASSWORD_CHANGE.ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.titlesEN.INVOICE_WITH_LINK.ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.titlesEN.INVOICE_WITH_PDF.ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.titlesEN.INVOICE_WITH_LINK_AND_XLSX
                    .ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.titlesEN.INVOICE_WITH_PDF_AND_XLSX
                    .ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.titlesEN.INVOICE_WITH_PDF_AND_CSV
                    .ediTemplateId
            );
            // vm.templatesToSend.push(vm.clientTemplates.titlesEN.CORRECTION_INVOICE.ediTemplateId);
            vm.templatesToSend.push(
                vm.clientTemplates.titlesEN.INVOICE_TOO_LARGE.ediTemplateId
            );

            vm.templatesToSend.push(
                vm.clientTemplates.templatesEN.ACCOUNT_OPENED.ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.templatesEN.EMAIL_ADDED.ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.templatesEN.REGISTRATION_FAIL.ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.templatesEN.PASSWORD_CHANGE.ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.templatesEN.INVOICE_WITH_LINK.ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.templatesEN.INVOICE_WITH_PDF.ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.templatesEN.INVOICE_WITH_LINK_AND_XLSX
                    .ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.templatesEN.INVOICE_WITH_PDF_AND_XLSX
                    .ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.templatesEN.INVOICE_WITH_PDF_AND_CSV
                    .ediTemplateId
            );
            // vm.templatesToSend.push(vm.clientTemplates.templatesEN.CORRECTION_INVOICE.ediTemplateId);
            vm.templatesToSend.push(
                vm.clientTemplates.templatesEN.INVOICE_TOO_LARGE.ediTemplateId
            );

            vm.templatesToSend.push(
                vm.clientTemplates.titlesFR.ACCOUNT_OPENED.ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.titlesFR.EMAIL_ADDED.ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.titlesFR.REGISTRATION_FAIL.ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.titlesFR.PASSWORD_CHANGE.ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.titlesFR.INVOICE_WITH_LINK.ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.titlesFR.INVOICE_WITH_PDF.ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.titlesFR.INVOICE_WITH_LINK_AND_XLSX
                    .ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.titlesFR.INVOICE_WITH_PDF_AND_XLSX
                    .ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.titlesFR.INVOICE_WITH_PDF_AND_CSV
                    .ediTemplateId
            );
            // vm.templatesToSend.push(vm.clientTemplates.titlesFR.CORRECTION_INVOICE.ediTemplateId);
            vm.templatesToSend.push(
                vm.clientTemplates.titlesFR.INVOICE_TOO_LARGE.ediTemplateId
            );

            vm.templatesToSend.push(
                vm.clientTemplates.templatesFR.ACCOUNT_OPENED.ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.templatesFR.EMAIL_ADDED.ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.templatesFR.REGISTRATION_FAIL.ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.templatesFR.PASSWORD_CHANGE.ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.templatesFR.INVOICE_WITH_LINK.ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.templatesFR.INVOICE_WITH_PDF.ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.templatesFR.INVOICE_WITH_LINK_AND_XLSX
                    .ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.templatesFR.INVOICE_WITH_PDF_AND_XLSX
                    .ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.templatesFR.INVOICE_WITH_PDF_AND_CSV
                    .ediTemplateId
            );
            // vm.templatesToSend.push(vm.clientTemplates.templatesFR.CORRECTION_INVOICE.ediTemplateId);
            vm.templatesToSend.push(
                vm.clientTemplates.templatesFR.INVOICE_TOO_LARGE.ediTemplateId
            );

            vm.templatesToSend.push(
                vm.clientTemplates.titlesDE.ACCOUNT_OPENED.ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.titlesDE.EMAIL_ADDED.ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.titlesDE.REGISTRATION_FAIL.ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.titlesDE.PASSWORD_CHANGE.ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.titlesDE.INVOICE_WITH_LINK.ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.titlesDE.INVOICE_WITH_PDF.ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.titlesDE.INVOICE_WITH_LINK_AND_XLSX
                    .ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.titlesDE.INVOICE_WITH_PDF_AND_XLSX
                    .ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.titlesDE.INVOICE_WITH_PDF_AND_CSV
                    .ediTemplateId
            );
            // vm.templatesToSend.push(vm.clientTemplates.titlesDE.CORRECTION_INVOICE.ediTemplateId);
            vm.templatesToSend.push(
                vm.clientTemplates.titlesDE.INVOICE_TOO_LARGE.ediTemplateId
            );

            vm.templatesToSend.push(
                vm.clientTemplates.templatesDE.ACCOUNT_OPENED.ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.templatesDE.EMAIL_ADDED.ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.templatesDE.REGISTRATION_FAIL.ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.templatesDE.PASSWORD_CHANGE.ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.templatesDE.INVOICE_WITH_LINK.ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.templatesDE.INVOICE_WITH_PDF.ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.templatesDE.INVOICE_WITH_LINK_AND_XLSX
                    .ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.templatesDE.INVOICE_WITH_PDF_AND_XLSX
                    .ediTemplateId
            );
            vm.templatesToSend.push(
                vm.clientTemplates.templatesDE.INVOICE_WITH_PDF_AND_CSV
                    .ediTemplateId
            );
            // vm.templatesToSend.push(vm.clientTemplates.templatesDE.CORRECTION_INVOICE.ediTemplateId);
            vm.templatesToSend.push(
                vm.clientTemplates.templatesDE.INVOICE_TOO_LARGE.ediTemplateId
            );

            ClientsService.postClientTemplates(
                vm.dhlClient.ediClientId,
                vm.templatesToSend
            )
                .then(function (response) {
                    vm.clientErrorText = null;
                    vm.clientSuccessText = $translate.instant(
                        "systemSettings.messages.templatesEditSuccess"
                    );
                })
                .catch(function (response) {
                    vm.clientErrorText = $translate.instant(
                        "systemSettings.messages.templatesEditFail"
                    );
                    vm.clientSuccessText = null;
                });
        }

        function changeActionTypeToText(actionType) {
            return filterFilter(
                vm.ActivityTypes,
                { name: actionType },
                true
            )[0].text();
        }

        vm.listTemplates = {
            first: function () {
                UtilService.get(vm.dhlTemplatesData, "first", null, null).then(
                    vm.listTemplates.then
                );
            },
            previous: function () {
                UtilService.get(vm.dhlTemplatesData, "prev", null, null).then(
                    vm.listTemplates.then
                );
            },
            next: function () {
                UtilService.get(vm.dhlTemplatesData, "next", null, null).then(
                    vm.listTemplates.then
                );
            },
            last: function () {
                UtilService.get(vm.dhlTemplatesData, "last", null, null).then(
                    vm.listTemplates.then
                );
            },
            then: function (response) {
                //add 'BENELUXUI' to url if missing
                fixTemplatesLinks(response);
                vm.dhlTemplatesData = response.data;
                vm.dhlTemplates = getEntityTemplates(response.data);
            },
        };
        vm.listTitles = {
            first: function () {
                UtilService.get(vm.dhlTitlesData, "first", null, null).then(
                    vm.listTitles.then
                );
            },
            previous: function () {
                UtilService.get(vm.dhlTitlesData, "prev", null, null).then(
                    vm.listTitles.then
                );
            },
            next: function () {
                UtilService.get(vm.dhlTitlesData, "next", null, null).then(
                    vm.listTitles.then
                );
            },
            last: function () {
                UtilService.get(vm.dhlTitlesData, "last", null, null).then(
                    vm.listTitles.then
                );
            },
            then: function (response) {
                //add 'BENELUXUI' to url if missing
                fixTemplatesLinks(response);
                vm.dhlTitlesData = response.data;
                vm.dhlTitles = getEntityTemplates(response.data);
            },
        };

        function setTooltip() {
            vm.htmlTooltip = true;
        }

        function disableTooltip() {
            vm.htmlTooltip = false;
        }

        function chooseOrDrop() {
            return $translate.instant("systemSettings.chooseOrDrop");
        }

        $scope.$watch(
            function () {
                var element = angular.element(
                    document.querySelector(".html-input")
                );
                return element.attr("class");
            },
            function (newValue) {
                if (newValue && newValue.indexOf("ng-invalid-pattern") !== -1) {
                    vm.htmlTooltip = true;
                } else if (!newValue || newValue.indexOf("ng-valid") !== -1) {
                    vm.htmlTooltip = false;
                }
            }
        );

        vm.init();
    }
})();
