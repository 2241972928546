(function () {
    "use strict";

    angular.module("edistradadhluiApp").factory("EntityService", EntityService);

    EntityService.$inject = [
        "$http",
        "$rootScope",
        "$q",
        "Principal",
        "API_URL",
    ];

    function EntityService($http, $rootScope, $q, Principal, API_URL) {
        var service = {
            changeEntity: changeEntity,
            setApiUrl: setApiUrl,
            setUrlDomain: setUrlDomain,
            getVersion: getVersion,
        };

        return service;

        function changeEntity(entity) {
            var deferred = $q.defer();
            switchEntity(entity)
                .then(function (response) {
                    // setApiUrl(entity);  //for now if we are in .nl we stay in .nl, no api url change after login
                    Principal.identity(true).then(function () {
                        //update user data, current source name etc.
                        deferred.resolve(response);
                    });
                })
                .catch(function (response) {
                    deferred.reject(response);
                });
            return deferred.promise;
        }

        function setUrlDomain(entity) {
            $rootScope.API_DOMAIN = entity;
            setApiUrl(entity);
        }

        function setApiUrl(entity) {
            if (entity === "BE") {
                $rootScope.API_URL = API_URL.BE;
            } else if (entity === "NL") {
                $rootScope.API_URL = API_URL.NL;
            } else {
                $rootScope.API_URL = API_URL.EC;
            }
            console.log("Set api url = " + $rootScope.API_URL);
        }

        function switchEntity(entity) {
            return $http({
                method: "POST",
                url: $rootScope.API_URL + "api/switch",
                params: { username: entity },
            });
        }

        function getVersion() {
            return $http({
                method: "GET",
                url: $rootScope.API_URL + "api/version",
            });
        }
    }
})();
