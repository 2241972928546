(function () {
    "use strict";

    angular
        .module("edistradadhluiApp")
        .factory("DocumentsService", DocumentsService);

    DocumentsService.$inject = [
        "$rootScope",
        "$http",
        "$httpParamSerializer",
        "UtilService",
        "GenerateURL",
    ];

    function DocumentsService(
        $rootScope,
        $http,
        $httpParamSerializer,
        UtilService,
        GenerateURL
    ) {
        var service = {
            list: list,
            getUserColumnConf: getUserColumnConf,
            setUserColumnConf: setUserColumnConf,
            getPdf: getPdf,
            getXlsx: getXlsx,
            resendEmailForAllDocuments: resendEmailForAllDocuments,
            resendEmailOnNewAdresses: resendEmailOnNewAdresses,
            retransferDocumentsToSmtp: retransferDocumentsToSmtp,
            getPdfURL: getPdfURL,
            getInvoiceXlsxURL: getInvoiceXlsxURL,
            getInvoiceCsvURL: getInvoiceCsvURL,
            getInvoiceXmlURL: getInvoiceXmlURL,
            getPdfsURL: getPdfsURL,
            getCsvsURL: getCsvsURL,
            getCsvsConcatenateURL: getCsvsConcatenateURL,
            getExcelURL: getExcelURL,
            getExcelConcatenateURL: getExcelConcatenateURL,
            getXMLURL: getXMLURL,
            getXlsxURL: getXlsxURL,
            isAvailable: isAvailable,
            submitDispute: submitDispute,
            getDisputeFormData: getDisputeFormData,
            getCsvTemplate: getCsvTemplate,
            getCsvAllTemplates: getCsvAllTemplates,
            setCsvTemplate: setCsvTemplate,
            updateCsvTemplate: updateCsvTemplate,
            deleteCsvTemplate: deleteCsvTemplate,
            getCustomCsvURL: getCustomCsvURL,
            getInvoiceCustomCsvURL: getInvoiceCustomCsvURL,
            getCustomCsvsConcatenateURL: getCustomCsvsConcatenateURL,
        };
        return service;

        function getCsvAllTemplates() {
            return $http({
                method: "GET",
                url: $rootScope.API_URL + "api/csv/templates/names",
            });
        }

        function getCsvTemplate(template) {
            return $http({
                method: "GET",
                url: $rootScope.API_URL + "api/csv/templates/name/" + template,
            });
        }

        function setCsvTemplate(body) {
            return $http({
                method: "POST",
                url: $rootScope.API_URL + "api/csv/templates/",
                data: body,
            });
        }

        function updateCsvTemplate(template, body) {
            return $http({
                method: "PUT",
                url: $rootScope.API_URL + "api/csv/templates/" + template,
                data: body,
            });
        }

        function deleteCsvTemplate(template) {
            return $http({
                method: "DELETE",
                url: $rootScope.API_URL + "api/csv/templates/name/" + template,
            });
        }

        function list(filter) {
            return $http({
                method: "POST",
                url: $rootScope.API_URL + "api/beneluxInvoices/getLimited",
                data: UtilService.starsToPercents(filter),
            });
        }

        function getUserColumnConf(UID) {
            return $http({
                method: "GET",
                url:
                    $rootScope.API_URL +
                    "api/beneluxUsers/" +
                    UID +
                    "/userConf",
            });
        }

        function setUserColumnConf(UID, columnConf) {
            return $http({
                method: "POST",
                url:
                    $rootScope.API_URL +
                    "api/beneluxUsers/" +
                    UID +
                    "/userConf",
                data: columnConf,
            });
        }

        function getPdf(EdiDocId) {
            return $http({
                method: "GET",
                url:
                    $rootScope.API_URL +
                    "api/beneluxInvoices/" +
                    EdiDocId +
                    "/pdf",
                responseType: "arraybuffer",
            });
        }

        function getXlsx(filter) {
            return $http({
                method: "POST",
                url:
                    $rootScope.API_URL +
                    "api/beneluxInvoices/getAsExcelByCustomCriteria",
                data: UtilService.starsToPercents(filter), //filter,
                responseType: "arraybuffer",
            });
        }

        function getPdfsURL(EdiDocIds, language) {
            var params = {
                ids: EdiDocIds,
                includePdf: true,
                //if waybill scans will be implemented, this param should be used and set to true when needed
                includeScan: false,
                language: language,
            };
            var serializedParams = $httpParamSerializer(params);
            var baseURL =
                $rootScope.API_URL + "api/beneluxInvoices/downloadPdfs";
            if (serializedParams.length > 0) {
                baseURL +=
                    (baseURL.indexOf("?") === -1 ? "?" : "&") +
                    serializedParams;
            }
            return baseURL;
        }

        //TODO implement backend
        function getCsvsURL(EdiDocIds, language) {
            var params = {
                ids: EdiDocIds,
                language: language,
            };
            var serializedParams = $httpParamSerializer(params);
            var baseURL =
                $rootScope.API_URL + "api/beneluxInvoices/downloadCsvs";
            if (serializedParams.length > 0) {
                baseURL +=
                    (baseURL.indexOf("?") === -1 ? "?" : "&") +
                    serializedParams;
            }
            return baseURL;
        }

        function getCustomCsvURL(EdiDocIds, language, template) {
            var params = {
                ids: EdiDocIds,
                language: language,
            };
            var serializedParams = $httpParamSerializer(params);
            var baseURL =
                $rootScope.API_URL + "api/csv/download/zip/" + template;
            if (serializedParams.length > 0) {
                baseURL +=
                    (baseURL.indexOf("?") === -1 ? "?" : "&") +
                    serializedParams;
            }
            return baseURL;
        }

        function getCsvsConcatenateURL(EdiDocIds, language) {
            var params = {
                ids: EdiDocIds,
                language: language,
            };
            var serializedParams = $httpParamSerializer(params);
            var baseURL =
                $rootScope.API_URL +
                "api/beneluxInvoices/downloadCsvsConcatenate";
            if (serializedParams.length > 0) {
                baseURL +=
                    (baseURL.indexOf("?") === -1 ? "?" : "&") +
                    serializedParams;
            }
            return baseURL;
        }

        function getCustomCsvsConcatenateURL(EdiDocIds, language, template) {
            var params = {
                ids: EdiDocIds,
                language: language,
            };
            var serializedParams = $httpParamSerializer(params);
            var baseURL =
                $rootScope.API_URL +
                "api/csv/download/concatenated-zip/" + template;
            if (serializedParams.length > 0) {
                baseURL +=
                    (baseURL.indexOf("?") === -1 ? "?" : "&") +
                    serializedParams;
            }
            return baseURL;
        }
        function getExcelURL(EdiDocIds, language) {
            var params = {
                ids: EdiDocIds,
                language: language,
            };
            var serializedParams = $httpParamSerializer(params);
            var baseURL =
                $rootScope.API_URL + "api/beneluxInvoices/downloadExcel";
            if (serializedParams.length > 0) {
                baseURL +=
                    (baseURL.indexOf("?") === -1 ? "?" : "&") +
                    serializedParams;
            }
            return baseURL;
        }
        function getExcelConcatenateURL(EdiDocIds, language) {
            var params = {
                ids: EdiDocIds,
                language: language,
            };
            var serializedParams = $httpParamSerializer(params);
            var baseURL =
                $rootScope.API_URL +
                "api/beneluxInvoices/downloadExcelConcatenate";
            if (serializedParams.length > 0) {
                baseURL +=
                    (baseURL.indexOf("?") === -1 ? "?" : "&") +
                    serializedParams;
            }
            return baseURL;
        }
        function getXMLURL(EdiDocIds, language) {
            var params = {
                ids: EdiDocIds,
                language: language,
            };
            var serializedParams = $httpParamSerializer(params);
            var baseURL =
                $rootScope.API_URL + "api/beneluxInvoices/downloadXML";
            if (serializedParams.length > 0) {
                baseURL +=
                    (baseURL.indexOf("?") === -1 ? "?" : "&") +
                    serializedParams;
            }
            return baseURL;
        }

        function resendEmailForAllDocuments(EdiDocIds) {
            return $http({
                method: "POST",
                url: $rootScope.API_URL + "api/beneluxInvoices/resendEmails",
                data: EdiDocIds,
            });
        }

        function resendEmailOnNewAdresses(EdiDocIds, Emails) {
            return $http({
                method: "POST",
                url: $rootScope.API_URL + "api/beneluxInvoices/sendNewEmails",
                data: {
                    docIds: EdiDocIds,
                    emails: Emails,
                },
            });
        }

        function retransferDocumentsToSmtp(EdiDocIds) {
            return $http({
                method: "POST",
                url:
                    $rootScope.API_URL +
                    "api/beneluxInvoices/sendDocClientsSmtp",
                data: {
                    docIds: EdiDocIds,
                },
            });
        }

        function getPdfURL(EdiDocId, download, forceFullDocument) {
            var params = {
                download: download,
                forceFullDocument: forceFullDocument,
            };
            var baseURL =
                $rootScope.API_URL + "api/beneluxInvoices/" + EdiDocId + "/pdf";
            return GenerateURL.generate(baseURL, params);
        }

        function getInvoiceXlsxURL(EdiDocId) {
            var params = {};
            var baseURL =
                $rootScope.API_URL +
                "api/beneluxInvoices/" +
                EdiDocId +
                "/xlsx";
            return GenerateURL.generate(baseURL, params);
        }

        function getInvoiceCsvURL(EdiDocId) {
            var params = {};
            var baseURL =
                $rootScope.API_URL + "api/beneluxInvoices/" + EdiDocId + "/csv";
            return GenerateURL.generate(baseURL, params);
        }

        function getInvoiceCustomCsvURL(EdiDocId, template) {
            var params = {};
            var baseURL =
                $rootScope.API_URL +
                "api/csv/template/customCsv/" +
                template +
                "/" +
                EdiDocId;
            return GenerateURL.generate(baseURL, params);
        }

        function getInvoiceXmlURL(EdiDocId) {
            var params = {};
            var baseURL =
                $rootScope.API_URL + "api/beneluxInvoices/" + EdiDocId + "/xml";
            return GenerateURL.generate(baseURL, params);
        }

        function getXlsxURL(filter) {
            var params = UtilService.starsToPercents(filter);
            var baseURL =
                $rootScope.API_URL +
                "api/beneluxInvoices/getAsExcelByCustomCriteria";
            return GenerateURL.generate(baseURL, params);
        }

        function isAvailable(uuid, type) {
            return $http({
                method: "GET",
                url: $rootScope.API_URL + "api/beneluxInvoices/isAvailable",
                params: {
                    documentId: uuid,
                    documentType: type,
                },
            });
        }

        function submitDispute(data) {
            return $http({
                method: "POST",
                url: $rootScope.API_URL + "api/beneluxInvoices/disputes/submit",
                headers: { "Content-Type": undefined },
                params: {
                    name: data.name,
                    email: data.email,
                    accountNumber: data.accountNumber,
                    invoiceNumber: data.invoiceNumber,
                },
                data: {
                    shipmentNumbers: getShipmentNumbers(data),
                    comments: getComments(data),
                    attachments: getAttachments(data),
                },
                transformRequest: function (data) {
                    var formData = new FormData();
                    formData.append(
                        "shipmentNumbers",
                        blobify(data.shipmentNumbers)
                    );
                    formData.append("comments", blobify(data.comments));

                    for (var i = 0; i < data.attachments.length; i++) {
                        var attachment = data.attachments[i];
                        if (attachment) {
                            formData.append(
                                "attachments",
                                new Blob([attachment], {
                                    type: "application/octet-stream",
                                }),
                                attachment.name
                            );
                        } else
                            formData.append(
                                "attachments",
                                new Blob([], {
                                    type: "application/octet-stream",
                                })
                            );
                    }

                    return formData;
                },
            });
        }

        function blobify(data) {
            var json = JSON.stringify(data);
            return new Blob([json], {
                type: "application/json",
            });
        }

        function getShipmentNumbers(data) {
            return getArrayOf("shipmentNumber", data);
        }
        function getComments(data) {
            return getArrayOf("comment", data);
        }
        function getAttachments(data) {
            return getArrayOf("attachment", data);
        }

        function getArrayOf(idx, data) {
            var arr = [];
            data.commentEntries.forEach(function (entry) {
                arr.push(entry[idx]);
            });
            return arr;
        }

        function getDisputeFormData(EdiDocId) {
            return $http({
                method: "GET",
                url:
                    $rootScope.API_URL +
                    "api/beneluxInvoices/" +
                    EdiDocId +
                    "/disputes",
                params: {},
            });
        }
    }
})();
