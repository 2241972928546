(function () {
    "use strict";

    angular
        .module("edistradadhluiApp")
        .controller("downloadController", downloadController);

    downloadController.$inject = [
        "$uibModalInstance",
        "ediDocIds",
        "language",
        "DocumentsService",
    ];

    function downloadController(
        $uibModalInstance,
        ediDocIds,
        language,
        DocumentsService
    ) {
        var vm = this;

        vm.data = {
            downloadPDF: true,
            downloadCSV: false,
            downloadCSVConcatenate: false,
            downloadExcel: false,
            downloadExcelConcatenate: false,
            downloadXML: false,
            customCSV: false,
            ediDocIds: ediDocIds,
            availableColumns: [],
            selectedColumns: [],
            savedConfigs: [],
            selectedConfig: "base_template",
            saveName: "",
        };

        vm.getDownloadURLs = getDownloadURLs;
        vm.checkboxClick = checkboxClick;

        vm.modal = {
            download: function () {
                $uibModalInstance.close();
            },
            cancel: function () {
                $uibModalInstance.dismiss("cancel");
            },
        };

        function loadCsvSelectedTemplate(template) {
            DocumentsService.getCsvTemplate(template)
                .then(function (response) {
                    if (Array.isArray(response.data.data)) {
                        vm.data.selectedColumns = response.data.data;
                    } else {
                        console.error(
                            "getCsvTemplate Unexpected response format",
                            response.data.data
                        );
                    }
                })
                .catch(function (error) {
                    console.error("Error fetching getCsvBaseTemplate", error);
                });
        }

        function loadCsvBaseTemplate() {
            DocumentsService.getCsvTemplate("base_template")
                .then(function (response) {
                    if (Array.isArray(response.data.data)) {
                        vm.data.availableColumns = response.data.data;
                    } else {
                        console.error(
                            "getCsvTemplate Unexpected response format",
                            response.data.data
                        );
                    }
                })
                .catch(function (error) {
                    console.error("Error fetching getCsvBaseTemplate", error);
                });
        }

        function loadCsvAllTemplates() {
            DocumentsService.getCsvAllTemplates()
                .then(function (response) {
                    if (Array.isArray(response.data.data)) {
                        vm.data.savedConfigs = response.data.data;
                    } else {
                        console.error(
                            "getCsvAllTemplates Unexpected response format",
                            response.data.data
                        );
                    }
                })
                .catch(function (error) {
                    console.error("Error fetching getCsvAllTemplates", error);
                });
        }

        function checkboxClick(fivarype) {
            if (fivarype === "XML") {
                resetSelections();
                vm.data.downloadXML = true;
            } else if (fivarype === "ExcelConcatenate") {
                resetSelections();
                vm.data.downloadExcelConcatenate = true;
            } else if (fivarype === "EXCEL") {
                resetSelections();
                vm.data.downloadExcel = true;
            } else if (fivarype === "CSVConcatenate") {
                resetSelections();
                vm.data.downloadCSVConcatenate = true;
            } else if (fivarype === "CSV") {
                resetSelections();
                vm.data.downloadCSV = true;
            } else if (fivarype === "PDF") {
                resetSelections();
                vm.data.downloadPDF = true;
            } else if (fivarype === "CustomCSV") {
                resetSelections();
                loadCsvBaseTemplate();
                loadCsvAllTemplates();
                loadCsvSelectedTemplate(vm.data.selectedConfig);
                vm.data.customCSV = true;
            }
        }

        function resetSelections() {
            vm.data.downloadPDF = false;
            vm.data.downloadCSV = false;
            vm.data.downloadCSVConcatenate = false;
            vm.data.downloadExcel = false;
            vm.data.downloadExcelConcatenate = false;
            vm.data.downloadXML = false;
            vm.data.customCSV = false;
        }

        function getDownloadURLs(
            ediDocIds,
            downloadPDF,
            downloadCSV,
            downloadCSVConcatenate,
            downloadExcel,
            downloadExcelConcatenate,
            downloadXML,
            customCSV
        ) {
            if (downloadPDF) {
                return getAsPdf(ediDocIds);
            } else if (downloadCSV) {
                return getCsvs(ediDocIds);
            } else if (downloadCSVConcatenate) {
                return getCsvsConcatenate(ediDocIds);
            } else if (downloadExcel) {
                return getExcel(ediDocIds);
            } else if (downloadExcelConcatenate) {
                return getExcelConcatenate(ediDocIds);
            } else if (downloadXML) {
                return getXML(ediDocIds);
            }else if(vm.data.customCSV && vm.data.concatenated){
                return getCustomCsvContacenateURL(ediDocIds, vm.data.selectedConfig)
            }
             else if (vm.data.customCSV) {
                return getCustomCsvURL(ediDocIds, vm.data.selectedConfig);
            }
        }

        function getAsPdf(ediDocIds) {
            return ediDocIds.length > 1
                ? DocumentsService.getPdfsURL(ediDocIds, language)
                : DocumentsService.getPdfURL(ediDocIds[0], true, null);
        }

        function getCsvs(ediDocIds) {
            return ediDocIds.length > 1
                ? DocumentsService.getCsvsURL(ediDocIds, language)
                : DocumentsService.getInvoiceCsvURL(ediDocIds[0]);
        }

        function getCsvsConcatenate(ediDocIds) {
            return ediDocIds.length > 1
                ? DocumentsService.getCsvsConcatenateURL(ediDocIds, language)
                : null;
        }

        function getExcel(ediDocIds) {
            return ediDocIds.length > 1
                ? DocumentsService.getExcelURL(ediDocIds, language)
                : DocumentsService.getInvoiceXlsxURL(ediDocIds[0]);
        }

        function getExcelConcatenate(ediDocIds) {
            return ediDocIds.length > 1
                ? DocumentsService.getExcelConcatenateURL(ediDocIds, language)
                : null;
        }

        function getXML(ediDocIds) {
            return ediDocIds.length > 1
                ? DocumentsService.getXMLURL(ediDocIds, language)
                : DocumentsService.getXMLURL(ediDocIds[0]);
        }

        function getCustomCsvURL(ediDocIds, template) {
            return ediDocIds.length > 1
                ? DocumentsService.getCustomCsvURL(ediDocIds, language, template)
                : DocumentsService.getInvoiceCustomCsvURL(ediDocIds[0],template);
        }

        function getCustomCsvContacenateURL(ediDocIds, template) {
            return ediDocIds.length > 1
                ? DocumentsService.getCustomCsvsConcatenateURL(ediDocIds, language, template)
                : null;
        }

        // ----- CSV GENERATOR FUNCTIONS -----
        vm.addRow = function () {
            if (vm.data.selectedAvailable && vm.data.selectedAvailable.length) {

                vm.data.selectedAvailable.forEach(function (selectedItem) {
                    if (!vm.data.selectedColumns.includes(selectedItem)) {
                        vm.data.selectedColumns.push(selectedItem);
                    }
                });


                vm.data.selectedAvailable = [];
            }
        };

        vm.removeRow = function () {
            if (vm.data.selectedSelected && vm.data.selectedSelected.length) {

                vm.data.selectedSelected.forEach(function (selectedItem) {
                    var index = vm.data.selectedColumns.indexOf(selectedItem);
                    if (index !== -1) {
                        vm.data.selectedColumns.splice(index, 1);
                    }
                });


                vm.data.selectedSelected = [];
            }
        };

        vm.moveUp = function () {
            if (vm.data.selectedSelected && vm.data.selectedSelected.length) {

                var selectedItems = vm.data.selectedSelected.slice();

                selectedItems.forEach(function (selectedItem) {
                    var index = vm.data.selectedColumns.indexOf(selectedItem);
                    if (index > 0) {

                        var temp = vm.data.selectedColumns[index];
                        vm.data.selectedColumns[index] =
                            vm.data.selectedColumns[index - 1];
                        vm.data.selectedColumns[index - 1] = temp;
                    }
                });
            }
        };

        vm.moveDown = function () {
            if (vm.data.selectedSelected && vm.data.selectedSelected.length) {

                var selectedItems = vm.data.selectedSelected
                    .slice()
                    .sort(function (a, b) {
                        return (
                            vm.data.selectedColumns.indexOf(b) -
                            vm.data.selectedColumns.indexOf(a)
                        );
                    });

                selectedItems.forEach(function (selectedItem) {
                    var index = vm.data.selectedColumns.indexOf(selectedItem);
                    if (index < vm.data.selectedColumns.length - 1) {

                        var temp = vm.data.selectedColumns[index];
                        vm.data.selectedColumns[index] =
                            vm.data.selectedColumns[index + 1];
                        vm.data.selectedColumns[index + 1] = temp;
                    }
                });
            }
        };

        vm.sortSelectedColumns = function () {
            if (!vm.data.sortOrder || !vm.data.selectedColumns.length) {
                return;
            }

            var isAscending = vm.data.sortOrder === "Ascending";

            vm.data.selectedColumns.sort(function (a, b) {
                if (typeof a === "string" && typeof b === "string") {
                    return isAscending
                        ? a.localeCompare(b)
                        : b.localeCompare(a);
                } else {
                    return isAscending ? a - b : b - a;
                }
            });
        };

        vm.saveConfig = function () {
            var templateName = vm.data.saveName || vm.data.selectedConfig;

            if (!templateName || !vm.data.selectedColumns.length) {
                console.warn("Template name or columns missing.");
                return;
            }

            var requestBody = {
                templateName: templateName,
                columns: vm.data.selectedColumns,
            };

            console.log("Saving CSV template:", requestBody);

            if (!vm.data.saveName) {
                DocumentsService.updateCsvTemplate(
                    vm.data.selectedConfig,
                    requestBody
                );
                alert("Configuration saved!");
            } else {
                DocumentsService.setCsvTemplate(requestBody)
                    .then(function (response) {
                        if (response.status === 201) {

                            if (!vm.data.savedConfigs.includes(templateName)) {
                                vm.data.savedConfigs.push(templateName);
                            }

                            vm.data.selectedConfig = templateName;

                            vm.data.saveName = "";
                        } else {
                            console.warn(
                                "Unexpected response status:",
                                response.status
                            );
                        }
                    })
                    .catch(function (error) {

                        var errorMessage = "An error occurred while saving the template.";

                        if (error.response && error.response.data && error.response.data.message) {
                            errorMessage = error.response.data.message;
                        } else if (error.message) {
                            errorMessage = error.message; 
                        }

                        if(error.status =="409"){
                            errorMessage = "Template with this name already exists";
                        }

                        alert(errorMessage);
                        console.error(
                            "Error saving template:",
                            error.status || error
                        );
                    });
            }
        };

        vm.loadConfig = function () {
            if (!vm.data.selectedConfig) {
                console.warn("No configuration selected for loading.");
                return;
            }

            console.log("Loading configuration:", vm.data.selectedConfig);
            loadCsvSelectedTemplate(vm.data.selectedConfig);
        };

        vm.devareConfig = function () {
            if (!vm.data.selectedConfig) {
                console.warn("No configuration selected for devarion.");
                return;
            }

            var index = vm.data.savedConfigs.indexOf(vm.data.selectedConfig);
            if (index > -1) {
                vm.data.savedConfigs.splice(index, 1);
                console.log("Devared configuration:", vm.data.selectedConfig);

                vm.data.selectedConfig = vm.data.savedConfigs.length
                    ? vm.data.savedConfigs[0]
                    : null;
            } else {
                console.warn("Configuration not found in savedConfigs.");
            }
        };

        vm.deleteConfig = function () {
            DocumentsService.deleteCsvTemplate(vm.data.selectedConfig)
                .then(function () {
                    loadCsvAllTemplates();
                    vm.data.selectedConfig = "base_template";
                    loadCsvSelectedTemplate(vm.data.selectedConfig);
                })
                .catch(function (error) {
                    console.log(
                        "ERROR : " +
                            (error.message || "Unknown error")
                    );
                });
        };

        vm.done = function () {
            alert("Configuration saved!");
        };
    }
})();
