(function () {
    'use strict';
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
    angular
        .module('edistradadhluiApp')
        .constant('VERSION', "132.0.0-26-g1eb7b979")
        .constant('DEBUG_INFO_ENABLED', false)
        .constant('API_URL', {
	"BE": "https://dhl.be.qas-benelux.edistrada.pl/edistrada2/",
	"EC": "https://dhl.nl.qas-benelux.edistrada.pl/edistrada2/",
	"NL": "https://dhl.nl.qas-benelux.edistrada.pl/edistrada2/"
})
        .constant('SHELL_LOGO_HREF', "/BENELUXUI")
;
})();
